import fetchIntercept from 'fetch-intercept';

export const AddBearerTokenAsHeader = (token: string) => {
  fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here
      config.headers.Authorization = `Bearer ${token}`;
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      const err = error instanceof Error ? error : new Error(String(error));
      return Promise.reject(err);
    },

    response: function (response) {
      // Modify the reponse object
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      const err = error instanceof Error ? error : new Error(String(error));
      return Promise.reject(err);
    },
  });
};
